.pass-wrapper {
    
    position: relative;
    display: flex;
    margin-bottom: 14px;

    span {
        position: absolute;
        top: 20%;
        right: 5%;
      }
      span:hover {
        color: #00fcb6;
        cursor: pointer;
      }
}