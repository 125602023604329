
section{
  // display: flex;

  .dropzone {
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;

    .selected-file {
      color: #000; 
      font-weight: bold;
    }
  }
  
  .selected-file-wrapper {
    text-align: center;
    table{
      width: 100%;
      .td-files-cat {
        width: 100px;
      }
    }
  }
}

  

  
