.side-bar {
    position: fixed;

    height: 100vh;
    width: 78px;
    background: #11101d;

    .logo-details {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo_name {
            display: none;
            font-size: 22px;
            color: #fff;
            font-weight: 600;
            // transition: 0.3s ease;
            // transition-delay: 0.1s;
        }
    }
    .nav-links {
        height: 100%;
        padding: 30px 0 150px 0;
        overflow: auto;
        li {
            position: relative;
            list-style: none;
            transition: all 0.4s ease;
            .iocn-link {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            i {
                height: 50px;
                min-width: 78px;
                text-align: center;
                line-height: 50px;
                color: #fff;
                font-size: 20px;
                cursor: pointer;
                transition: all 0.3s ease;
            }
            a {
                display: flex;
                align-items: center;
                text-decoration: none;

                .link_name {
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    transition: all 0.4s ease;
                }

                &:hover+.sub-menu {
                    opacity: 1;
                    display: block;
                }

            }

            .sub-menu {
                padding: 6px 6px 14px 80px;
                margin-top: -10px;
                background: #1d1b31;
                display: none;

                a {
                    color: #fff;
                    font-size: 15px;
                    padding: 5px 0;
                    white-space: nowrap;
                    opacity: 0.6;
                    transition: all 0.3s ease;
                }

                .link_name {
                    display: none;
                }

                &.blank {
                    opacity: 1;
                    pointer-events: auto;
                    padding: 3px 20px 6px 16px;
                    opacity: 0;
                    pointer-events: none;
                }

            }

            &.showMenu {
                i.arrow {
                    transform: rotate(-180deg);
                }

                sub-menu {
                    display: block;
                }
            }

            &:hover {

                .sub-menu {
                    &.blank {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        &:hover {
            background: #1d1b31;
        }

    }

    &::-webkit-scrollbar {
        display: none;
    }


}


.side-bar.close {
    // width: 78px;

    .logo-details {
        .logo_name {
            transition-delay: 0s;
            opacity: 0;
            pointer-events: none;
        }
    }
    .nav-links {
        overflow: visible;
        li {
            .iocn-link {
                display: block;
            }
            a {
                .link_name {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            .sub-menu {
                position: absolute;
                left: 100%;
                top: -10px;
                margin-top: 0;
                padding: 10px 20px;
                border-radius: 0 6px 6px 0;
                opacity: 0;
                display: block;
                pointer-events: none;
                transition: 0s;
                .link_name {
                    font-size: 18px;
                    opacity: 1;
                    display: block;
                }
                i.arrow {
                    transform: rotate(-180deg);
                }
                sub-menu {
                    display: block;
                }
                a {
                    &:hover {
                        opacity: 1;
                    }
                }
            }
            &:hover {
                .sub-menu {
                    top: 0;
                    opacity: 1;
                    pointer-events: auto;
                    transition: all 0.4s ease;
                }
            }
        }
        i.arrow {
            display: none;
        }

    }
    header {
        left: 50px;
        width: calc(100% - 50px);
    }
}
.bx-menu {
    margin: 0 15px;
    cursor: pointer;
}

@media (min-width: 992px) {
    .side-bar {
        width: 260px;
        .logo-details {
            .logo_name {
                display: block;
                // transition: 0.3s ease;
                // transition-delay: 0.1s;
                // opacity: 1;
            }
        }
        .nav-links {
            overflow: auto;
            &::-webkit-scrollbar {
                display: none;
            }
            li {
                .iocn-link {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                a {
                    .link_name {
                        font-size: 18px;
                        font-weight: 400;
                        color: #fff;
                        transition: all 0.4s ease;
                        opacity: 1;
                    }
                }
                i.arrow {
                    display: block;
                }
                &.showMenu {
                    i {
                        &.arrow {
                            transform: rotate(-180deg);
                        }
                    }
                    .sub-menu {
                        display: block;
                    }
                }
                .sub-menu {
                    padding: 6px 6px 14px 80px;
                    margin-top: -10px;
                    background: #1d1b31;
                    display: none;
                    a {
                        color: #fff;
                        font-size: 15px;
                        padding: 5px 0;
                        white-space: nowrap;
                        opacity: 0.6;
                        transition: all 0.3s ease;
                        &:hover {
                            opacity: 1;
                        }
                    }
                    .link_name {
                        display: none;
                    }
                    &.blank {
                        opacity: 1;
                        pointer-events: auto;
                        padding: 3px 20px 6px 16px;
                        opacity: 0;
                        pointer-events: none;
                    }
                }
                &:hover {
                    .sub-menu {
                        &.blank {
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }
}