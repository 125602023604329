@import 'assets/styles/variables';

label {
  &.secondary {
    color: $blue;
    font-family: 'Poppins-SemiBold';
    font-size: 14px !important;
  }

  &.login {
    color: $grey;
    font-family: 'Poppins-SemiBold';
  }

  &.width-middle {
    width: 48%;
  }
}

select {
  background-color: #fef4e2;
}