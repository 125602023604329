.div-quote {
    display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
    // margin-top: 5px;
    // width:90%;
    margin:auto;
    // border: 1px solid #6666;
    padding: 5px;
  }

//   .invoice-content {
//     font-family: 'Poppins';
//     width: 21cm;
//     height: 29.7cm;

// }