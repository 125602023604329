@import 'assets/styles/variables';

select {
  // padding: 2px 20px;
  // border-radius: 5px;
  // background: $white;
  // border: solid 1px $greylight;
  // background-color: #fef4e2;
  &.secondary{
    color: black;
    font-family: 'Poppins';
    font-size: 14px !important; 
  }
  padding: 8px 15px;
  border-radius: 5px !important;
  margin: 5px 0px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  font-size: 14px !important;
  font-weight: 300; 
  &.reduce {
    min-width: 20%;
  }
  &.form {
    margin-bottom: 30px;
    margin-top: 5px;
    width: 100%;
  }
}
