.div-quotestatus {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    padding: 10px 20px 10px 20px;
  
  
    @media screen and (min-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
  
    }
  
  
  }

  .actionner{
      button{
        background-color: transparent;
        padding: 0px;
      }
  }