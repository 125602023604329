.form-check-label {
    margin-bottom: 0;
        font-size: 14px;
}

.form-check-inline .form-check-input{
    margin-right: 0rem !important;
}

.select-fct{
    select {
        background-color: #eeeded;
      }
}