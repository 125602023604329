$yellow: #FFBE25;
$yellowflat : #FFFBF1;
$green: #70B684;
$greenflat: #7eb68e;
$white: #FFFFFF;
$whitelight: #ffffff7a;
$black: #000;
$grey: #707070;
$greylight: #EBEBEB;
$red: #ED4337;
$blue:#0e384d;
$skyblue: #3b7bac;
$orange:#f6ae2d;

$primary: $orange;
$secondary: $blue;