@import 'assets/styles/variables';

input {
  border: none;
  border-bottom: 1px solid;
  border-color: $black;
  // background-color: violet;
  padding: 5px 0;
  outline-width: 0;
  width: 100%;
  font-family: 'Poppins';
  &::-webkit-input-placeholder,
  &::-moz-placeholder {
    color: $grey;
  }
  &.primary {
    border-color: $yellow;
    &::-webkit-input-placeholder {
      color: $yellowflat;
    }
    &::-moz-placeholder {
      color: $yellowflat;
    }
  }
  &.secondary {
    // border-color: $white;
    // background-color: $green; 
    // color: white;
    &::-webkit-input-placeholder {
      color: $whitelight;
    }
    &::-moz-placeholder {
      color: $whitelight;
    }
  }
  &.login {
    font-family: 'Poppins';
    border-color: $grey;
    color: $grey;
    padding-top: 15px;
    margin-bottom: 50px;
    &::-webkit-input-placeholder {
      color: $grey;
    }
    &::-moz-placeholder {
      color: $grey;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
