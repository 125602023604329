@font-face {
  font-family: 'Poppins-Light';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Black';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Poppins-Black.ttf') format('truetype');
}
