
// .top {
//     height: calc(40% - 140px);
// }
.center {
    height: 350px;
    text-align: center;
    vertical-align: middle;
    font-family: Verdana;
    padding-top: 100px;
    padding-top: 10px;
}

.circle {
    margin: auto;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    background: #c0c6cc;
}
.circle_text {
    line-height: 260px;
    font-size: 100px;
    color: #ffffff;
    font-weight: bold;
}
