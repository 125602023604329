th {
    text-align: center !important;
    border: none !important;
    text-transform: uppercase;
}

td {
    text-align: center !important;
}

.actionContainer {
    justify-content: center;
}

.Name {
    text-transform: uppercase;
}
