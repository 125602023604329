button {
    // background-color: #3b7bac;
    // color: black !important;
    padding: 2px 2px !important;
}
.div-footer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 10px 20px 10px 50px;

}
.div-discount{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

// .div-total{
//     display:flex;
//     align-items: flex-end;
// }
.quote-table-left-header{
    width: 450px;
}

.total-ttc{
    // text-align: end;
    font-weight: bold;
}
