@import 'assets/styles/variables';

label {
  
  &.secondary{
    color: $grey;
    font-family: 'Poppins-SemiBold';
  }
  &.width-middle{
    width: 48%;
  }

  textarea {
    font-size: 14px !important;
    font-weight: 300; 
    font-family: 'Poppins';
    resize: none;
    background-color: #fef4e2;
    // border: none;
    // border-bottom: 10px solid;
    // border-color: $black;
    // padding: 5px 0;
    // outline-width: 0;
    width: 100%;
   
    // padding: 8px 15px;
    border-radius: 5px !important;
    margin: 5px 0px;
    box-sizing: border-box;
    border: 1px solid #ccc;
     
    &::-webkit-input-placeholder,
    &::-moz-placeholder {
      color: $grey;
    }
    &.primary {
      border-color: $yellow;
      
    //   &::-webkit-input-placeholder {
    //     color: $yellowflat;
    //   }
    //   &::-moz-placeholder {
    //     color: $yellowflat;
    //   }
    }
    // &.secondary {
    //   // border-color: $white;
    //   // background-color: $green; 
    //   // color: white;
    //   &::-webkit-input-placeholder {
    //     color: $whitelight;
    //   }
    //   &::-moz-placeholder {
    //     color: $whitelight;
    //   }
    // }
  }


}


