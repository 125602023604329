@import 'assets/styles/variables';

label {
  &.secondary{
    color: $blue;
    // font-family: 'Poppins-SemiBold';
    font-size: 14px !important; 
  }
  &.login {
    color: $grey;
    font-family: 'Poppins-SemiBold';
  }
  &.width-middle{
    width: 48%;
  }
}

input {
  padding: 8px 15px;
  border-radius: 5px !important;
  margin: 5px 0px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  font-size: 14px !important;
  font-weight: 300;  
  background-color: #fef4e2;
  


}