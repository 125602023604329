@import 'reset';
@import 'breakpoints';
@import 'responsive';
@import 'typography';
@import 'variables.scss';

// .text-center {
//   text-align: center;
// }
// .text-end {
//   text-align: right;
// }
// .text-start {
//   text-align: left;
// }


.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

// .d-block {
//   display: block;
// }

// .m-auto {
//   margin: auto;
// }

.error {
  color: $red;
}

.title {
  color: $grey;
  font-size: 30px;
  letter-spacing: 3px;
}

.green_text {
  font-family: 'Poppins-Bold';
  color: $secondary;
  letter-spacing: 3px;
  font-size: 18px;
  margin: 0;
}

.title_spacing {
  padding-left: 80px;
}

.subtitle_spacing {
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;

}

.searchbar_spacing {
  margin-left: 80px;
}

.div_searchbar_spacing {
  padding-right: 80px;
}

.title_searchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.searchbar_align {
  text-align: end;
}

.title_no_padding_top {
  padding-top: 0 !important;
}

.text_white {
  color: $white;
}

.full-height {
  height: calc(100vh - 80px) !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 80px 40px 80px;
}

.flex-row-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.div-cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  padding: 10px 20px 10px 20px;


  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);

  }


}