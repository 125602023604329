@import 'assets/styles/variables';

// #### RESET ####
table,
thead,
tbody,
tfoot,
tr,
th,
td {
  //  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: inherit;
  text-align: left;
  font-weight: inherit;
  font-size: 13px;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
}

// #### RESET ####

table {
  background: $white;
  width: calc(100% - 160px);
  margin: 0 10px;

  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  thead {
    color: $white;
    font-family: 'Poppins-Bold';
    font-weight: lighter;
    top: 15px;
    z-index: 1;
    color: $blue;

    tr {
      height: 60px;
      vertical-align: middle;
      text-align: center;

      th {

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        flex: 1;
        padding: 0 2%;
      }
    }
  }

  tbody {
    font-family: 'Poppins-Medium';
    color: $grey;

    tr {
      height: 40px;
      vertical-align: middle;
      box-sizing: border-box;

      td {
        padding: 0 2%;
        text-align: center;
      }

    }
  }
}