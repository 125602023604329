.courses-container {
    text-decoration: none;
    position: relative !important;

    .course {
        background-color: #fff;
        border-radius: 10px;
        width: 450px;
        height: 200px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        display: flex;
        max-width: 100%;
        margin: 20px;
        overflow: hidden;
        width: 400px;

        background:
            -webkit-linear-gradient(-15deg,
                rgba(39, 110, 163, 0.9) 69%,
                rgba(255, 255, 255, 1) 0%);


        .course-preview {
            color: #fff;
            padding: 30px;
            max-width: 250px;
            text-align: left;

            h2 {
                letter-spacing: 1px;
                margin: 10px 0;
                font-size: 20px;
                text-transform: uppercase;
                white-space: nowrap;
                font-weight: bold;
                color: white;
            }

            small {
                letter-spacing: 1.8px;
            }

        }

        .course-info {
            flex: 1;
            padding: 30px;
            width: 100%;

            .progress-container {
                margin-top: 80px;

                .icon {
                    size: 300px;
                    padding-left: 35px;
                }
            }
        }

    }

    .progress-card {
  
        transform: translate(47px, -54px);
        width: 150px;
        background-color: #f6ae2d;
        height: 8px;
    }


}
