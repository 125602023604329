@import 'assets/styles/variables';

.table-header {
    margin-bottom: 30px;

    // h1 {
    //     padding-top: 70px;
    // }

    .logoplus {
        padding-top: 56px;
    }
}


thead {
    background-color: #3b7bac;

    th {
        font-size: 15px;
    }
}

tbody {
    td {
        border: lightgrey 1px solid;
    }
}