@import 'assets/styles/variables';

.boolean_checkbox_with_label {
  display: flex;
  flex-direction: row;
  // align-items: ;
  vertical-align: center;
  padding: 2px 0;
  cursor: pointer;
  // padding-left: unset;

  p {
    font-size: 14px;
    color: $blue;
    font-family: 'Poppins-SemiBold';
    padding-top: 11px;
    
    padding-left: unset;
    // padding-left: 5px;

    margin: auto;
  }

}

// La chekbox inactif pour etre alignée avec l'autre
div.checkbox-label:nth-child(1)>div:nth-child(1)>div:nth-child(2) {
  padding-left: 45px;
}


//Le padding des div des etiquettes Statut et Catégories
div.checkbox-label:nth-child(1)>div:nth-child(1)>div:nth-child(1) {
  padding: unset;
  
}

div.checkbox-label:nth-child(2)>div:nth-child(1)>div:nth-child(1) {
  padding: unset;
}

.checkbox-label {
  display: flex;
  justify-content: initial;
  padding-left: unset !important;
  padding-right: unset !important;
margin-bottom: 20px;
  // &.children {
  //   padding-left: 1px;

  // }

}

.switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 34px;
  padding: auto;
  vertical-align: center;
  ;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $grey;
  color: white;
  transition: .4s;
  text-align: center;
  padding-right: 20px;
  font-size: 13px;
  padding-left: 30px;
  padding-top: 6px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  // text-align: right;
}

input:checked+.slider {
  // background-color: green;
  background-color: #F9ce81;
  color: white;
  text-align: center;
  padding-left: 10px;
}

input:focus+.slider {
  box-shadow: 0 0 1px green;
}

input:checked+.slider:before {
  transform: translateX(86px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}