.card-body{
    tbody
    { 
        td {
        border: none;
      }
      tr{
          height: 10px;
      }
    }
}
