@import 'assets/styles/variables';
body {
  // background: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
  font-family: Noto Sans, Helvetica, sans-serif;
  color: #666;
  letter-spacing: 1px;
  // padding: 10px;
  min-height: 100vh;
  margin: 0;
}

.contains {
  margin-left: 79px;

}

header {
  text-align: center;
  // margin: 10px 0 10px 0px;
  // padding-left: 70px;
  // left:50px;
  // width: calc(100% - 50px);
  // display: flex;

  .links {
    text-transform: uppercase;
    text-align: center;
    font-size: 150%;
    margin-bottom: 20px;

    .link:link,
    .link:visited {
      padding: 5px 10px;
      font-weight: 100;
      margin: 1rem;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      border-bottom: 2px solid transparent;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;

      &.active {
        font-weight: 500;
      }
    }

    .link:hover,
    .link:active {
      border-bottom: 2px solid #fff;
    }
  }
}



h1,
h2 {
  color: #666;
}

.main-content {
  // width: 80%;
  // margin: auto auto 100px auto;
  // margin :auto;
  margin: 0 4%;
}

// .sidebar-content {
//   width: 20%;
// }

.main {
  margin-left: 10px;
}

.form {
  width: 50%;
  float: left;
  margin-bottom: 50px;
}
div.form-group{
  margin-bottom: 0rem;

  select{
    outline: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(23, 24, 24, 0.6);
    border: 1px solid #cecece;
  }
}
.form-label{
  margin-bottom: 0.1rem;
}


.div-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.div-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width:90%;
  margin:auto;
  padding: 5px;
}
.div-button{
  display: flex;
  justify-content: center;
  align-items: left;
  padding: 10px 0;
}

.div-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.client {
  margin: 10px;
  width: 288px;
  overflow: hidden;

  .client-title {
    font-weight: bold;
  }

  .client-details {
    margin-bottom: 1rem;

    &>div {
      margin-bottom: 0.5rem;
    }
  }
}

.main-form {
  color: #222222;
  font-size: 18px;
  margin: auto;
}

.form-client {
  margin: auto;
  padding: 20px;
}

.input-control {
  border: 1px solid #ccc;
  color: #555555;
  background-color: #fff;
  height: 40px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 0;
  display: block;
  margin-bottom: 5px;
  padding: 10px;
  width: 80%;

  &:focus {
    outline: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(23, 24, 24, 0.6);
    border: 1px solid #cecece;
  }
}

.switch-group{
  span{
    color: #555555;
    height: 35px;
    font-size: 15px;
    line-height: 1.3333333;
  }

}
button {
  margin-right: 5px;
}

.submit-btn {
  margin-top: 1rem;
}

.btn-primary {


  padding: 5px 30px;
  color: #FFFFFF;
  background-color: #eb730f!important;
  border-color: #eb730f !important;
  opacity: 0.75;
}

.btn-primary:hover {
    list-style: none;
    opacity: 1;
    text-decoration: none;
    color: #fff;
    background-color: #eb6e0f !important; //27097
    border-color: #eb670f !important; //25292
}


.btn-secondary {

  padding: 5px 30px;
  color: #FFFFFF;
  background-color: #555555;
  border-color: #555555 !important;
  border-radius: 2px;
  opacity: 0.75;
  font-weight: 400 !important;
}

.btn-secondary:hover {
    list-style: none;
    opacity: 1;
    text-decoration: none;
    color: #fff;
    background-color: #555555 !important; //27097
    border-color: #555555 !important; //25292
    
}

// .login {
//   text-align: center;
//   padding: 10px;
//   height: 200px;
//   line-height: 200px;

//   button {
//     font-size: 20px;
//   }
// }

.errorMsg {
  color: #f21e08;
  background: #fff0f0;
  padding: 10px;
}

.successMsg {
  color: #00ff00;
  background: #077707;
  padding: 10px;
}

.centered-message {
  width: 500px;
  margin: auto;
}

.message {
  color: #0e1b1e;
  font-size: 22px;
  margin-left: 10px;
  padding-top: 15px;
}

.home-content{
  height: 100vh;
  width: calc(100vw - 78px);
  background-color: #f1f2f7;
  margin-left: 78px;
}

.actionContainer {
  display: flex;
}

.add-link {
  font-size: x-large;
  padding: 5px 20px;
  color: #FFFFFF;
  background-color: #eb730f;
  opacity: 0.75;
  border-radius: 50px;
  border: 1px solid #eb730f ;
}

.add-link:hover {
  list-style: none;
    opacity: 1;
    text-decoration: none;
    color: #FFFFFF;
}

.edit-link {
  color: #0e384d;
  font-size: xx-large;
}

.delete-button {
  background-color: none;
}

.div-delete-link {
  cursor: pointer;
}

.delete-link {
  color: red;
  font-size: xx-large;
}

.table td {
  vertical-align: middle !important;
}


@media screen and (max-width: 400px) {
  header {
    .links {
      a {
        font-size: 1.2rem;
      }

      .link:link,
      .link:visited {
        margin: unset;
      }
    }
  }

  .main-form {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .home-content {
      width: calc(100vw - 260px);
      margin-left: 260px;
  }
}