@import 'assets/styles/variables';

button {
  padding: 20px 40px;
  // color: $white;
  // background-color: $primary;
  border: none;
  border-radius: 5px !important;
  line-height: 20px;
  cursor: pointer;
  

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.secondary {
    background-color: $secondary;
  }

  &.form {
    background-color: $blue;
    color: $white;
    font-size: 14px;
    letter-spacing: .1rem;
  }

  &.spacing {
    margin: 0 20px;
  }

  svg {
    padding-left: 10px;
  }
}