@import 'assets/styles/variables';

.div-showuser {

    // .div-header {
    //     background-color: #d3d3d3;
    //     padding: 25px;
    //     margin-bottom: 57px;

    //     // h1 {
    //     //     padding-top: 70px;
    //     // }

    // }

    .content {
        display: flex;
        justify-content: center;
        border-bottom: white !important;

        tbody {

            th {
                border: lightgrey 1px solid;
                width: 250px;
                background-color: $skyblue;
                color: white !important;
            }

            td {

                width: 400px;
            }
        }
    }
}