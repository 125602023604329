
@import 'assets/styles/variables';

select{
    background-color: #fef4e2;
}
textarea {
    font-size: 12px !important;
    font-weight: 300; 
    font-family: 'Poppins';
    resize: none;
    background-color: #fef4e2;
    
    width: 500px;

    border-radius: 5px !important;
    margin: 5px 0px;
    box-sizing: border-box;
    border: 1px solid #ccc;
     
    &::-webkit-input-placeholder,
    &::-moz-placeholder {
      color: $grey;
    }
    &.primary {
      border-color: $yellow;

    }
}
.presta_quantite{
    width: 50px !important;
}