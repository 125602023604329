
// .bg-nav {
//   background-color: #fefeff;
//   background-image: linear-gradient(to bottom, #fefeff, #f4f6f7);
//   background-repeat: repeat-x;
//   border-bottom: 1px solid #e4e8ea;

//   margin: 0;
// }

.navbar-nav{
  flex-direction: row !important;
}

#navbarSupportedContent {
  justify-content: flex-end;
}
