@import 'assets/styles/variables';

.reset {
      display: flex;
      flex: 4;
      flex-direction: column;
      justify-content: space-around;
      .logo{
        padding-top: 20px;
        width: 20%;
        align-items: center;
      }
      .title {
        padding: 50px 0 30px 0;
      }
      .container {
        // margin: 0 auto;
        // width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        flex: 1 1 auto;
        button {
          // margin: 30px auto;
          margin-top:50px;
        }
        .title {
          padding: 50px 0;
        }
      }
      a {
        color: $green;
        text-decoration: none;
        font-family: 'Poppins-Medium';
      }
    }
