@import 'assets/styles/variables';

.div-header {
    background-color: rgb(185, 185, 185);
    flex-direction: row !important;
    justify-content: space-around !important;
    padding: 25px;


    h1 {
        padding: 0;
        margin-left: 60px;
    }

    .button-plus {
        padding-right: 20px;
    }

    .invoice {
        width: 50;
        height: 50;
        padding-right: 20px;
    }
}